import React, { useEffect, useState } from 'react';
import styles from './ProductsPage.module.scss';
import ProductsImageCarousel from './components/product-image-carousel/ProductsImageCarousel';
import ProductsCategories from './components/products-categories/ProductsCategories';
import { useTranslation } from 'react-i18next';
import ProducersList from './components/producers-list/ProducersList';
import NewestProducts from './components/newest-products/NewestProducts';
import MostPurchasedProducts from './components/most-purchased-products/MostPurchasedProducts';
import Search from './components/search/Search';
import ProductsSearchedView from './components/products-searched-view/ProductsSearchedView';
import CartPanel from '../../shared/cart-panel/CartPanel';
import InfoContainer from './components/info-container/InfoContainer';
import { useMediaQuery } from '@mui/material';

interface ProductsPageProps {
    selectedCategory?: string;
    isSearched: boolean;
    updateIsSearched: any;
    handleCategorySelect: any;
    onCartItemsChange: any;
}


export default function ProductsPage({ selectedCategory, isSearched, updateIsSearched, handleCategorySelect, onCartItemsChange }: ProductsPageProps) {
    const { t, i18n } = useTranslation('translation');
    const [openCartPanel, setOpenCartPanel] = useState<boolean>(false);
    const isWideScreen = useMediaQuery('(min-width:768px)');


    const handleCartItemsChange = () => {
        onCartItemsChange();
        handleToggleCartPanel(true);
    };

    const handleSearchedClicked = () => {
        updateIsSearched(true);
    };
    const handleToggleCartPanel = (value: boolean) => {
        setOpenCartPanel(value);
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.image_carousel}>
                <ProductsImageCarousel />
            </div>
            {isWideScreen && <InfoContainer />}
            <div className={`${styles.categories_and_products} ${selectedCategory ? `${styles.product_list}` : ''}`}>
                {(isWideScreen || (!isSearched && !isWideScreen)) && (
                    <ProductsCategories
                        isSearched={isSearched}
                        searchedClicked={handleSearchedClicked}
                        onSelectCategory={handleCategorySelect}
                        categoryId={selectedCategory}
                        displayPickupPoint={false}
                    />
                )}

                {!isWideScreen && !isSearched && <InfoContainer />}
                {!isSearched ? (
                    <div className={styles.products_container}>

                        {isWideScreen &&
                            <Search
                                searchedClicked={handleSearchedClicked}
                                onSelectCategory={handleCategorySelect}
                                categoryId={selectedCategory} />
                        }
                        <NewestProducts onCartItemsChange={handleCartItemsChange} />
                        <MostPurchasedProducts onCartItemsChange={handleCartItemsChange} />
                    </div>
                ) : (
                    <div className={styles.products_searched_container}>
                        {isWideScreen &&
                            <Search
                                searchedClicked={handleSearchedClicked}
                                onSelectCategory={handleCategorySelect}
                                categoryId={selectedCategory} />
                        }
                        <ProductsSearchedView onCartItemsChange={handleCartItemsChange} />
                    </div>
                )}
            </div>
            {
                !selectedCategory && !isSearched && (
                    <div className={styles.producers_and_promo}>
                        <div className={styles.producers_list}>
                            <ProducersList />
                        </div>
                    </div>
                )
            }
            {openCartPanel && <CartPanel toggleOpenCartPanel={handleToggleCartPanel}></CartPanel>}
        </div >
    );
}
